body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.zoominheader {
  background-size: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-animation: zoomin 10s linear infinite;
  animation: zoomin 10s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}

.zoomoutheader {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 10s linear infinite;
  animation: zoomout 10s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}

.zoomleft {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 50s linear infinite;
  animation: zoomleft 50s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}


@-webkit-keyframes zoomleft {
  0% {
      transform: translateX(0%) scale(1.5);
  }

  100% {
      transform: translateX(100%) scale(1);
  }
}

@keyframes zoomleft {
  0% {
      transform: translateX(0%) scale(1.5);
  }

  100% {
      transform: translateX(100%) scale(1);
  }
}

.zoomright {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 50s linear infinite;
  animation: zoomleft 50s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}


@-webkit-keyframes zoomright {
  0% {
      transform: translateX(-0%) scale(1.5);
  }

  100% {
      transform: translateX(-100%) scale(1);
  }
}

@keyframes zoomright {
  0% {
      transform: translateX(-0%) scale(1.5);
  }

  100% {
      transform: translateX(-100%) scale(1);
  }
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(2);
  }
}

@keyframes zoomin {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(2);
  }
}

/*End of Zoom in Keyframes */


@-webkit-keyframes zoomout {
  0% {
      transform: scale(1.5);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes zoomout {
  0% {
      transform: scale(1.5);
  }

  100% {
      transform: scale(1);
  }
}


.font-active {
  border: 0.0625rem solid #E1A6FF!important;
  /* color: #fff; */
}

.position-circle {
  width: 40px;
  border-radius: 6px;
  background: rgba(29, 27, 65, 0.63);
  border: 1px solid rgba(29, 27, 65, 0.63);
  height: 40px;
  margin-top: 8px;
  /* border-radius: 100%; */
}

.progress-bar-text{
  position: absolute;
  top: 0;
  font-size: 13px;
  font-weight: 700;
}

.Face_narration {
  text-align: center;
}

.Face_narration_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Face_narration h2 {
  font-size: 26px;
}

.Face_narration_section span {
  font-size: 26px;
  border-bottom: 1px solid #d1f0d1;
  display: inline-block;
  position: relative;
  top: 5px;
}

.Face_narration_section .Thumb img {
  width: 51px;
  transform: rotateY(180deg);
}

.Face_narration_section .Thumbs img {
  width: 51px;
  transform: rotateY(180deg)rotatex(180deg);
}

.Face_narration_do {
  padding: 10px;
  width: 550px;
  margin-right: 12px;
  overflow: hidden;
}

.Face_narration_do li,
.Face_narration_dont li {
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 8px;
  text-align: left !important;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 100px;
}

.Face_narration_do li span,
.Face_narration_dont li span {
  font-size: 14px;
  color: #fff;
  border: none;
  position: static;

}

.Face_narration_do li .green {
  color: #fff;
  margin-right: 10px;
  font-size: 31px;
  width: 40px;
  padding: 6px;
  height: 40px;
  background: green;
  border-radius: 100%;
}

.Face_narration_img {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
}

.Face_narration_img img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.Face_narration_dont {
  padding: 10px;
  width: 550px;
}

.Face_narration_dont li .red {
  color: #fff;
  margin-right: 10px;
  font-size: 31px;
  width: 40px;
  padding: 6px;
  height: 40px;
  background: red;
  border-radius: 100%;
}